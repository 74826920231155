import { Validation, ValueObject } from '@singularsystems/neo-core';

export default class ApprovalAuditLookupCriteria extends ValueObject {

    constructor() {
        super();
        this.makeObservable();
    }

    public awardApprovalId: number = 0;

    // Client only properties / methods

    protected static addBusinessRules(rules: Validation.Rules<ApprovalAuditLookupCriteria>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Approval Audit Lookup Criteria";
    }
}