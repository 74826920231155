import { AppServices } from "@singularsystems/neo-core"
import DividendDocumentsApiClient from "./ApiClients/DividendDocumentsApiClient"
import { AppService, AppTypes } from '../../App/Services/AppService';
import { Config } from "../../App/Models/Config/Config";

const Types = {
    ...AppTypes,
    Dividends: {
        ApiClients: {
            DividendDocumentApiClient: new AppServices.ServiceIdentifier<DividendDocumentsApiClient>("Dividends.ApiClients")
        }
    },
    Config: AppServices.NeoTypes.Config.ConfigModel.asType<Config>()
}

export { AppService, Types}