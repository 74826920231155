import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ParticipantDocumentLookup extends LookupBase {

    public participantDocumentId: number = 0;

    public fileDescriptorId: number = 0;

    @Attributes.Date()
    public documentDate: Date = new Date();

    public documentType: string = "";

    public documentName: string = "";

    // Client only properties / methods

    @Attributes.Observable()
    public isLoading = false;

    public dividendDocumentId: number = 0;

    // When created from an award document.

    public awardDocumentCalculatedId: string | null = null;

    public participantOfferId: number | null = null;

    public participantOfferAwardDocumentId: number | null = null;

    public incentiveSchemeId: number | null = null;

    public incentiveSchemeDocumentId: number | null = null;

    public acceptedAwardSummary: boolean = false;

    public html: string | null = null;

    public get findId() {
        if (this.participantDocumentId) {
            return "pd-" + this.participantDocumentId;
        } else if (this.participantOfferAwardDocumentId) {
            return "ad-" + this.participantOfferAwardDocumentId;
        } else if (this.incentiveSchemeDocumentId) {
            return "id-" + this.incentiveSchemeDocumentId;
        } 
        else if (this.dividendDocumentId) {
            return "dd-" + this.dividendDocumentId;
        }else {
            return "pa-" + this.participantOfferId;
        }
    }
}