import { DateUtils } from '@singularsystems/neo-core';
import { NeoGrid } from '@singularsystems/neo-react';
import React from 'react';
import CashTransactionLookup from '../Models/CashTransactionLookup';
import CashTransactionLookupGrouped from '../Models/CashTransactionLookupGrouped';
import TradeRequestAdminExtraDetailsLookup from '../Models/Trading/TradeRequestAdminExtraDetailsLookup';

interface ITradeDetailComponentProps {
    extraDetails: TradeRequestAdminExtraDetailsLookup;
}

export class TradeTransactionsComponent extends React.Component<ITradeDetailComponentProps> {

    constructor(props: ITradeDetailComponentProps) {
        super(props);

        this.processTransactions();
    }

    private transactions!: CashTransactionLookup[];
    private currencySymbol1 = "";
    private currencySymbol2 = ""
    private groups: CashTransactionLookupGrouped[] | null = null;

    private processTransactions() {
        const transactions = [...this.props.extraDetails.cashTransactions];

        if (transactions.length > 0) {

            this.currencySymbol1 = transactions[0].currencySymbol;
            this.currencySymbol2 = transactions.filter(c => c.currencySymbol !== this.currencySymbol1)[0]?.currencySymbol;

            // Split different currencies into 2 columns.
            for (let transaction of this.props.extraDetails.cashTransactions) {
                if (transaction.isCurrencyConversion && transaction.currencySymbol === this.currencySymbol2) {
                    const convertFrom = transactions.find(c => c.isCurrencyConversion && c.group === transaction.group && c.currencySymbol === this.currencySymbol1);
                    if (convertFrom) {
                        convertFrom.amountCurrency2 = transaction.amount;
                        transactions.remove(transaction);
                    }
                }
                if (transaction.currencySymbol === this.currencySymbol1) {
                    transaction.amountCurrency1 = transaction.amount;
                }
                if (transaction.currencySymbol === this.currencySymbol2) {
                    transaction.amountCurrency2 = transaction.amount;
                }
            }

            // Group transaction types on the same day.
            const grouped = transactions.groupBy(c => DateUtils.format(c.effectiveDate, "dd MMM yyyy") + c.transactionType, (k, i, d) =>
                CashTransactionLookupGrouped.mapFrom<CashTransactionLookupGrouped>({
                    effectiveDate: i.effectiveDate,
                    transactionType: i.transactionType,
                    amountCurrency1: d.sum(c => c.amountCurrency1 ?? 0),
                    amountCurrency2: d.sum(c => c.amountCurrency2 ?? 0),
                    details: d
                }));

            this.transactions = transactions;
            if (grouped.length < transactions.length) {
                this.groups = grouped;
            }
        } else {
            this.transactions = [];
        }
    }

    public render() {

        if (this.transactions.length === 0) {
            return <div>Trade request currently has no transactions.</div>
        }

        if (this.groups) {
            return <div><NeoGrid.Grid items={this.groups}>
                {(group, groupMeta) => (
                    <NeoGrid.RowGroup expandProperty={groupMeta.isExpanded}>
                        <NeoGrid.Row>
                            <NeoGrid.Column display={groupMeta.effectiveDate} />
                            <NeoGrid.Column display={groupMeta.transactionType} />
                            <NeoGrid.Column label={`Amount (${this.currencySymbol1})`} display={groupMeta.amountCurrency1} alignment="right" sum />
                            {this.currencySymbol2 &&
                                <NeoGrid.Column label={`Amount (${this.currencySymbol2})`} display={groupMeta.amountCurrency2} alignment="right" sum />}
                        </NeoGrid.Row>
                        <NeoGrid.ChildRow>
                            {this.renderDetails(group.details)}
                        </NeoGrid.ChildRow>
                    </NeoGrid.RowGroup>
                )}
            </NeoGrid.Grid>
                {this.props.extraDetails?.buyDetails &&
                    this.renderBuySection(this.props.extraDetails)}</div>
        } else {
            return <div>{this.renderDetails(this.transactions)}
                {this.props.extraDetails?.buyDetails &&
                    this.renderBuySection(this.props.extraDetails)}
            </div>
        }
    }

    private renderDetails(transactions: CashTransactionLookup[]) {
        return (
            <NeoGrid.Grid items={transactions}>
                {(item, meta) => (
                    <NeoGrid.Row>
                        <NeoGrid.Column display={meta.effectiveDate} />
                        <NeoGrid.Column display={meta.transactionType} />
                        <NeoGrid.Column display={meta.transactionDetail} />
                        <NeoGrid.Column label={`Amount (${this.currencySymbol1})`} display={meta.amountCurrency1} alignment="right" sum />
                        {this.currencySymbol2 &&
                            <NeoGrid.Column label={`Amount (${this.currencySymbol2})`} display={meta.amountCurrency2} alignment="right" sum />}
                    </NeoGrid.Row>
                )}
            </NeoGrid.Grid>
        )
    }

    private renderBuySection(extraDetails: TradeRequestAdminExtraDetailsLookup) {
        const buyDetails = extraDetails?.buyDetails

        return buyDetails &&
            <div className="mt-3">
                <div className="table-caption">Transfer Details</div>
                <NeoGrid.Grid items={[buyDetails]}>
                    {(item, meta) => (
                        <NeoGrid.Row>
                            <NeoGrid.Column display={meta.transferDate} />
                            <NeoGrid.Column display={meta.quantity} />
                            <NeoGrid.Column display={meta.pricePerUnit} numProps={{ currencySymbol: this.currencySymbol1 }} />
                            <NeoGrid.Column display={meta.value} numProps={{ currencySymbol: this.currencySymbol1 }} />
                            <NeoGrid.Column display={meta.brokerage} />
                            <NeoGrid.Column display={meta.brokerageVat} />
                            <NeoGrid.Column display={meta.transferTax} />
                            <NeoGrid.Column display={meta.cgtPrice} />
                        </NeoGrid.Row>
                    )}
                </NeoGrid.Grid>
            </div>
    }
}