import { AppServices } from "@singularsystems/neo-core";
import DividendDocumentsApiClient from "./ApiClients/DividendDocumentsApiClient";
import { Types } from "./DividendsTypes";

const dividendsAppModule = new AppServices.Module("Dividends", container => {
    container.bind(Types.Dividends.ApiClients.DividendDocumentApiClient).to(DividendDocumentsApiClient).inSingletonScope();
});

export {
    dividendsAppModule
}