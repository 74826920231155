import { Data, Model, Validation } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import { Types } from '../DividendsTypes';
import DividendDocumentLookup from '../Models/Lookup/DividendDocumentLookup';

export interface IDividendDocumentsApiClient {

    /**
     * Gets a list of dividend documents.
     * @param participantId Participant id.
     * @returns A list of document lookups.
     */
    getDividendDocuments(participantId: string): AxiosPromise<Array<Model.PlainObject<DividendDocumentLookup>>>;

    /** 
     * Downloads the dividend statement token.
     * @param dividendDocumentId Dividend document id.
     * @param documentName Document name.
     */
    exportDocumentsAsync(dividendDocumentId: number, documentName: string): AxiosPromise<string>;

    /** 
     * Download dividend statement pdf.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     */
    getDownloadDocumentAsyncUrl(token: string, dividendDocumentId: number, documentName: string): string;


    // Client only properties / methods
}

@injectable()
export default class DividendDocumentsApiClient extends Data.ApiClientBase implements IDividendDocumentsApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.DividendsApi.ApiPath}/documents`);
    }

    public getDividendDocuments(): AxiosPromise<Array<Model.PlainObject<DividendDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}/dividend-documents`);
    }

    public exportDocumentsAsync(dividendDocumentId: number, documentName: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/dividend-document-download-token?dividendDocumentId=${dividendDocumentId}&documentName=${encodeURIComponent(documentName)}`);
    }

    public getDownloadDocumentAsyncUrl(token: string, dividendDocumentId: number, documentName: string): string {
        return `${this.apiPath}/dividend-document-download-pdf?token=${encodeURIComponent(token)}&dividendDocumentId=${dividendDocumentId}&documentName=${encodeURIComponent(documentName)}`;
    }


    // Client only properties / methods
}