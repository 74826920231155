import { NeoModel, Components, EnumHelper, Model, Attributes, FileUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../TransactionsTypes';
import { PortfolioGroupType } from '../../Models/Portfolio/Calculation/GroupHelper'
import { Awards } from '../../../../App';
import { runInAction } from 'mobx';
import TradeVM from './../TradeVM';
import IncentiveGroup, { VestingType } from '../../Models/Portfolio/IncentiveGroup';
import AppDataLookup from '../../Models/Portfolio/AppDataLookup';
import CalculationTranche from '../../Models/Portfolio/Calculation/CalculationTranche';
import CalculatorDataRequest from '../../Models/Portfolio/CalculatorDataRequest';
import CalculatorData from '../../Models/Portfolio/CalculatorData';
import RatesLookup from '../../Models/RatesLookup';
import CalculationGroup from '../../Models/Portfolio/Calculation/CalculationGroup';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import SettlementExchangeRateLookup from '../../Models/Portfolio/SettlementExchangeRateLookup';
import { TradeType } from '../../Models/Trading/TradeType';
import PortfolioCriteria from '../../../../Reporting/ParticipantsApp/Models/PortfolioCriteria';

@NeoModel
export default class PortfolioVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private awardsDataCache = AppService.get(Types.Awards.Services.AwardsDataCache),
        private transactionsDataCache = AppService.get(Types.Transactions.Services.DataCache),
        public layout = AppService.get(Types.Shared.Services.AppLayout),
        public portfolioService = AppService.get(Types.Transactions.Services.PortfolioService),
        private apiClient = AppService.get(Types.Transactions.ApiClients.PortfolioApiClient),
        public authService = AppService.get(Types.Shared.Services.STAuthenticationService),
        public participantApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient),
        public reportingApiClient = AppService.get(Types.Reporting.ApiClients.ReportingApiClient),
    ) {

        super(taskRunner);
    }

    public tradeRequestTask = AppService.get(Types.Neo.TaskRunner);

    public incentiveGroups: IncentiveGroup[] = [];
    public portfolioData: AppDataLookup | null = null;
    public paymentCountryId: number | null = null;
    public tradeVM: TradeVM | null = null;

    public hasCalculationData = false;
    public isAdvancedCalculatorMode = false;

    @Attributes.Float()
    @Attributes.Nullable()
    public customTaxRate: number | null = null;

    private _vestingType = VestingType.AllAwards;

    public get vestingType() {
        return this._vestingType;
    }
    public set vestingType(value: VestingType) {
        this._vestingType = value;
        for (let group of this.incentiveGroups) {
            group.vestingType = value;
        }
    }

    public isCalculateMode: boolean = false;
    public showCannotTradeReasons = false;

    public get validSelections() {
        return this.incentiveGroups.reduce((p, c) => p.concat(c.groupedList.filter(c => c.hasQuantity && c.canTrade)), [] as CalculationGroup[]);
    }

    public get invalidSelections() {
        return this.incentiveGroups.reduce((p, c) => p.concat(c.groupedList.filter(c => c.hasQuantity && !c.canTrade)), [] as CalculationGroup[]);
    }

    public async initialise() {
        return this.getInitialData();
    }

    public async getInitialData() {

        const results = await this.taskRunner.waitForAll({
            portfolioData: this.portfolioService.ensureLoaded(this.taskRunner, { allowNullCurrency: true, fetchPendingTrades: true }, Components.ErrorDisplayType.ThrowError),
            incentiveGroups: this.awardsDataCache.incentiveGroupsLookup.getDataAsync(),
        });

        runInAction(async () => {
            this.portfolioData = results.portfolioData;
            this.setupGroups(this.portfolioData, results.incentiveGroups);
            if (this.portfolioService.appData?.settings.secondmentConfirmation) {
                const result = await this.taskRunner.waitFor(this.participantApiClient.getSecondments());

                this.portfolioData.participantSecondments.set(result.data);
            }
        });
    }

    private setupGroups(appData: AppDataLookup, incentiveGroups: Awards.IncentiveGroupLookup[]) {

        const trancheBalances: CalculationTranche[] = [];
        const incentiveGroupIndex = new Map<number, IncentiveGroup>();
        const defaultSchemeGroup = incentiveGroups.find(c => c.isDefault) ?? Awards.IncentiveGroupLookup.CreateDefault();

        for (let scheme of appData.incentiveSchemes) {
            // set not to show if hideFromParticipants is true
            if (!scheme.hideFromParticipants) {
                const tranches = appData.trancheBalances.filter(c => c.incentiveSchemeId === scheme.incentiveSchemeId);

                if (tranches.length > 0) {
                    const group = scheme.incentiveGroupId === null ? defaultSchemeGroup : incentiveGroups.find(c => c.incentiveGroupId === scheme.incentiveGroupId)!;
                    let extendedGroup = incentiveGroupIndex.get(group.incentiveGroupId);

                    if (!extendedGroup) {
                        extendedGroup = new IncentiveGroup(group);
                        incentiveGroupIndex.set(group.incentiveGroupId, extendedGroup);
                    }

                    const calculationTranches = tranches.map(t => new CalculationTranche(t));
                    trancheBalances.push(...calculationTranches)
                    extendedGroup.allTrancheBalances.push(...calculationTranches);
                }
            }
        }

        const groups = Array.from(incentiveGroupIndex.values()).sortBy(c => c.info.incentiveGroupId);
        groups.forEach(g => g.finalise());

        this.incentiveGroups = groups;
    }

    public getGroupMenuItems(): Components.IButtonDropDownItem[] {
        let groupByOptions = EnumHelper.asList(PortfolioGroupType).map(item => ({ text: item.name, data: item.id }));
        if (this.isCalculateMode) {
            return groupByOptions.filter(c => c.data !== PortfolioGroupType.ByInstrument);
        } else {
            return groupByOptions;
        }
    }

    public async enterCalculationMode(forTrading: boolean) {
        if (this.isCalculateMode) {
            return true;
        }
        if (!forTrading || this.incentiveGroups.find(c => c.hasSelectedGroups())) {

            const selectedTranches: CalculationTranche[] = [];

            for (let group of this.incentiveGroups) {
                const tranchesToAdd = forTrading ? group.getTranchesOfSelectedGroups() : group.getTranchesWithBalance();
                selectedTranches.push(...tranchesToAdd);
            }

            let currencyExchangeRates = selectedTranches.filter(st => st.trancheBalance.settlementInstrumentCode &&
                st.trancheBalance.instrument.currencyCode !== st.trancheBalance.settlementInstrumentCurrencyCode)
                .groupBy(st => `${st.instrument.currencyCode}-${st.trancheBalance.settlementInstrumentCurrencyCode}`)
                .map(st => (
                    { 
                        fromCurrencyCode: st.item.trancheBalance.instrument.currencyCode,
                        toCurrencyCode: st.item.trancheBalance.settlementInstrumentCurrencyCode
                    }
                ));

            try {
                await this.setUpCalculatorData(selectedTranches, forTrading, currencyExchangeRates);
                return true;
            } catch (e) {
                return false;
            }

        } else {
            return false;
        }
    }

    private async setUpCalculatorData(tranches: CalculationTranche[], forTrading: boolean, currencyExchangeRates: Model.PartialPlainObject<SettlementExchangeRateLookup>[]) {
        const results = await this.taskRunner.waitForAll({
            calculationData: this.apiClient.processCalculatorData(CalculatorDataRequest.fromTrancheBalances(tranches.map(c => c.trancheBalance), currencyExchangeRates)),
            rates: this.transactionsDataCache.rates.getDataAsync()
        }, { allowPost: true });

        runInAction(() => {
            if (this.portfolioData!.instrumentEditMode && forTrading) {
                this.portfolioData!.instrumentEditMode = false;
                this.notifications.addInfo("Prices have been reset to market", "", NotificationDuration.Standard);
            }

            this.customTaxRate = null;

            this.portfolioData!.trancheBalances.forEach(trancheBalance => {
                trancheBalance.customTaxRate = null;
            })

            const calculatorData = CalculatorData.fromJSObject<CalculatorData>(results.calculationData.data);
            calculatorData.populateTranches(this.portfolioData!.incentiveSchemes, RatesLookup.fromJSObject(results.rates), tranches);

            for (let group of this.incentiveGroups) {
                group.enterCalculationMode(forTrading);
            }

            this.showCannotTradeReasons = false;
            this.isCalculateMode = true;
            this.isAdvancedCalculatorMode = !forTrading;
        });
    }

    public clearCalculateMode() {
        runInAction(() => {
            for (let group of this.incentiveGroups) {
                group.clearCalculationMode();
            }
            this.isCalculateMode = false;
            this.isAdvancedCalculatorMode = false;
            this.tradeVM = null;
        });
    }

    public limitPriceConfirmations: CalculationGroup[] | null = null;

    public confirmLimitPrice(group: CalculationGroup, resetToMarket: boolean) {
        if (resetToMarket) {
            group.isLimitPrice = false;
        }
        if (this.limitPriceConfirmations) {
            this.limitPriceConfirmations.remove(group);
            if (this.limitPriceConfirmations.length === 0) {
                this.limitPriceConfirmations = null;
                this.tradeVM = new TradeVM(this);
            }
        }

        return this.tradeVM !== null;
    }

    public startTrades() {
        const confirmations: CalculationGroup[] = [];
        for (let group of this.incentiveGroups) {
            confirmations.push(...group.groupedList.filter(c => c.isLimitPrice));
        }
        if (confirmations.length > 0) {
            this.limitPriceConfirmations = confirmations;
        } else {
            this.tradeVM = new TradeVM(this);
        }
    }

    public reCalcSellToBuys(instrumentId: number | undefined = undefined) {
        for (let group of this.incentiveGroups) {
            group.groupedList.forEach(groupedList => {
                if (instrumentId !== undefined) {
                    if (groupedList.instrument.instrumentId === instrumentId && groupedList.lastTradeType === TradeType.SellToCover) {
                        groupedList.calcSellToBuy();
                    }
                } else {
                    if (groupedList.lastTradeType === TradeType.SellToCover) {
                        groupedList.calcSellToBuy();
                    }
                }
            })
        }
    }

    public updateCustomTaxRates() {
        var trancheBalances = this.portfolioData!.trancheBalances;
        
        runInAction(() => {
            for (let trancheBalance of trancheBalances) {
                trancheBalance.customTaxRate = this.customTaxRate;
            }

            this.reCalcSellToBuys();
        })
    }

    public async downloadParticipantStatement() {
        var portfolioCriteria =  new PortfolioCriteria()
        portfolioCriteria.participantId = this.authService.user!.participantId
        
        this.taskRunner.run(async () => {
            const blobData = await this.reportingApiClient.getPortfolioPdfAsync(portfolioCriteria.toQueryObject());
            if (blobData) {
                FileUtils.showSaveFile(blobData);
            }
        })
    }
}