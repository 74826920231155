import { AppServices, Routing } from '@singularsystems/neo-core';
import ReportingApiClient from './ApiClients/ReportingApiClient';
import { ReportingTypes } from './ReportingTypes';

// Modules
const reportingModule = new AppServices.Module("Clients", container => {
    // Api Clients
    container.bind(ReportingTypes.ApiClients.ReportingApiClient).to(ReportingApiClient).inSingletonScope();
});

export { reportingModule }