import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../Clients/Common/ClientsTypes';
import PortfolioCriteria from '../Models/PortfolioCriteria';

export interface IReportingApiClient {

    /** 
     * Gets the portfolio report as a pdf document.
     * @returns A task representing the asynchronous operation
     */
    getPortfolioPdfAsync(criteria: Model.PartialPlainNonTrackedObject<PortfolioCriteria>): AxiosPromise<Blob>;

    // Client only properties / methods
}

@injectable()
export default class ReportingApiClient extends Data.ApiClientBase implements IReportingApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ReportingApi.ApiPath}/Reporting`);
    }

    public getPortfolioPdfAsync(criteria: Model.PartialPlainNonTrackedObject<PortfolioCriteria>): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/reports/portfolio/pdf?${Utils.getQueryString(criteria)}`, { responseType: "blob" });
    }

    // Client only properties / methods
}