import { Attributes, DateUtils, Misc, Model, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

export default class PortfolioCriteria extends Model.CriteriaBase {
    static typeName = "PortfolioCriteria";

    constructor() {
        super();
        this.makeObservable();
    }

    public participantId: string | null = "";

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public balanceDate: Date | null = DateUtils.today();

    public separatedType: SeparatedType = SeparatedType.Exclude;

    public includeZeroBalances: boolean = false;

    public incentiveSchemeIds: number[] = [];
    // Client only
}

export enum SeparatedType {
    Exclude = 1,
    Include = 2,
    SeparatedOnly = 3,
}