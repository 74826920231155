import { AppServices } from "@singularsystems/neo-core"
import { IReportingApiClient } from "./ApiClients/ReportingApiClient"

// Symbols to expose outside of this module
const ReportingTypes = {
    ApiClients: {
        ReportingApiClient: new AppServices.ServiceIdentifier<IReportingApiClient>("Reporting.ApiClients.ReportingApiClient"),
    }
}

export { ReportingTypes }