import { Attributes, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Routing, Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../ParticipantsTypes';
import ParticipantDocumentLookup from '../Models/Lookups/ParticipantDocumentLookup';
import { Types as DividendTypes } from '../../../Dividends/ParticipantsApp/DividendsTypes';
import DividendDocumentLookup from '../../../Dividends/ParticipantsApp/Models/Lookup/DividendDocumentLookup';

@NeoModel
export default class DocumentsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private participantApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient),
        private myAwardsApiClient = AppService.get(Types.Awards.ApiClients.MyAwardsApiClient),
        private awardDocumentsQueryApiClient = AppService.get(Types.Awards.ApiClients.AwardDocumentsQueryApiClient),
        public termsAndConditionsService = AppService.get(Types.Identity.Services.TermsAndConditionsService),
        public dividendApiClient = AppService.get(DividendTypes.Dividends.ApiClients.DividendDocumentApiClient)) {

        super(taskRunner);
    }

    public criteria = new Criteria();

    public documentList: ParticipantDocumentLookup[] = [];

    public selectedDocument: ParticipantDocumentLookup | null = null;

    public dividendDocuments: DividendDocumentLookup[] = [];

    public async initialise() {

        const result = await this.taskRunner.waitForAll({
            participants: this.participantApiClient.getDocumentLookupListAsync(),
            awardDocuments: this.myAwardsApiClient.participantDocumentLookupList(),
            dividendDocuments: this.dividendApiClient.getDividendDocuments()
        });

        const documentList: ParticipantDocumentLookup[] = [];
        documentList.push(...List.fromJSArray(ParticipantDocumentLookup, result.participants.data));

        result.dividendDocuments.data.forEach(element => {
            let newDoc = new ParticipantDocumentLookup();
            newDoc.documentName = element.documentName;
            newDoc.documentType = element.documentType;
            newDoc.documentDate = new Date(element.documentDate);
            newDoc.html = element.html;
            newDoc.dividendDocumentId = element.dividendDocumentId
            documentList.push(newDoc);
        });
   

        for (let document of result.awardDocuments.data) {
            const awardDocument = ParticipantDocumentLookup.mapFrom<ParticipantDocumentLookup>({
                incentiveSchemeDocumentId: document.incentiveSchemeDocumentId,
                documentType: document.awardDocumentType,
                documentName: document.documentName,
                documentDate: document.documentDate,
                participantOfferId: document.participantOfferId,
                participantOfferAwardDocumentId: document.participantOfferAwardDocumentId,
                awardDocumentCalculatedId: document.participantOfferAwardDocumentId >= 0 ? "pd-" + document.participantOfferAwardDocumentId : "id-" + -document.participantOfferAwardDocumentId,
                acceptedAwardSummary: document.acceptedAwardSummary,
            });
            documentList.push(awardDocument);
        }

        this.documentList = documentList;
    }

    public get filteredDocuments(): ParticipantDocumentLookup[] {
        let searchString = new RegExp(this.criteria.searchText, "i");

        return this.documentList.filter(doc =>
            (this.criteria.startDate === null || doc.documentDate.getTime() >= this.criteria.startDate.getTime()) &&
            (this.criteria.endDate === null || doc.documentDate.getTime() <= this.criteria.endDate.getTime()) &&
            (!this.criteria.searchText || doc.documentName.search(searchString) >= 0 || doc.documentType.search(searchString) >= 0) &&
            (!this.criteria.documentType || doc.documentType === this.criteria.documentType)
        );
    }

    public get documentTypes() {
        return this.documentList.groupBy(c => c.documentType, (k, i, d) => i.documentType).sort().map(i => ({
            id: i,
            text: i
        }));
    }

    public async findDocument(documentNo: Routing.IViewParameter) {
        let document: ParticipantDocumentLookup | undefined = undefined;
        if (documentNo.value) {
            document = this.documentList.find(c => c.findId === documentNo.asString());
        }
        if (document) {
            await this.viewDocument(document);
        } else {
            this.selectedDocument = null;
        }
        if (!this.selectedDocument) {
            documentNo.reset();
        }
    }

    private async viewDocument(document: ParticipantDocumentLookup) {

        if (document.participantDocumentId && document.fileDescriptorId) {
            document.isLoading = true;
            const response = await this.participantApiClient.getDocumentDownloadUrl(document.participantDocumentId, document.fileDescriptorId);
            document.isLoading = false;
            window.location.href = response.data;
        } 
        else if ((document.participantOfferId || document.incentiveSchemeDocumentId) && !document.html) {
            document.isLoading = true;
            const response = await this.awardDocumentsQueryApiClient.documentLookup(document);
            const documentInfo = response.data;
            if (documentInfo.downloadFileDescriptor) {
                const urlResponse = await this.awardDocumentsQueryApiClient.getDownloadUrl(documentInfo);
                window.location.href = urlResponse.data;
            } else {
                document.html = documentInfo.acceptedAwardDocumentHTML ? documentInfo.acceptedAwardDocumentHTML : documentInfo.awardDocumentHTML;
            }
            document.isLoading = false;
        }

        if (document.html) {
            this.selectedDocument = document;
        }
    }

    public async downloadHtmlDocument() {
        if (this.selectedDocument && this.selectedDocument.participantOfferId && this.selectedDocument.dividendDocumentId === 0) {
            const token = await this.taskRunner.waitFor(
                this.awardDocumentsQueryApiClient.getHtmlDocumentPdfDownloadToken(this.selectedDocument.participantOfferId, this.selectedDocument.participantOfferAwardDocumentId ?? 0, this.selectedDocument.acceptedAwardSummary));

            const downloadUrl = this.awardDocumentsQueryApiClient.getDownloadHtmlDocumentPdfUrl(this.selectedDocument.participantOfferId, this.selectedDocument.participantOfferAwardDocumentId ?? 0, token.data);
            window.location.href = downloadUrl;
        }
        else if (this.selectedDocument && this.selectedDocument.dividendDocumentId > 0) {
            const token = await this.taskRunner.waitFor(
               this.dividendApiClient.exportDocumentsAsync(this.selectedDocument!.dividendDocumentId, this.selectedDocument!.documentName)
            );

            const url = this.dividendApiClient.getDownloadDocumentAsyncUrl(token.data, this.selectedDocument!.dividendDocumentId, this.selectedDocument!.documentName);

            if (url) {
                window.location.href = url;
            }
        }
    }
}

@NeoModel
class Criteria extends ModelBase {

    @Attributes.Date()
    @Attributes.Nullable()
    public startDate: Date | null = null;

    @Attributes.Date()
    @Attributes.Nullable()
    public endDate: Date | null = null;

    @Attributes.Nullable()
    public documentType: string | null = null;

    public searchText: string = "";
}